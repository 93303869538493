var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container", attrs: { direction: "vertical" } },
    [
      _c("river-header", [_c("span", [_vm._v("xxx流程")])]),
      _c(
        "el-main",
        { staticClass: "wrap-main" },
        [
          _c(
            "keep-alive",
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                1
              ),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [!_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }