<template>
  <el-container class="wrap-container" direction="vertical">
    <river-header> <span>xxx流程</span></river-header>
    <el-main class="wrap-main">
      <keep-alive>
        <transition name="fade-transform" mode="out-in">
          <router-view v-if="$route.meta.keepAlive" />
        </transition>
      </keep-alive>
      <transition name="fade-transform" mode="out-in">
        <router-view v-if="!$route.meta.keepAlive" />
      </transition>
    </el-main>
  </el-container>
</template>

<script>
import RiverHeader from './components/river-header';
export default {
  name: 'FormDesignLayout',
  data() {
    return {};
  },
  components: {
    RiverHeader
  },
  methods: {
    /**
     * 获取菜单数据
     * @param {number} param
     * @returns {number}
     */
    initData() {}
  },
  created() {}
};
</script>

<style lang="scss" scoped>
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  overflow-x: hidden;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  overflow-x: hidden;
  transform: translateX(-30px);
}

.wrap-container {
  width: 100%;
  height: 100%;
  .wrap-main {
    width: 100%;
    height: calc(100% - 60px);
    padding: 0px;
  }
}

.main-scrollbar {
  height: 100%;

  /deep/ .is-horizontal {
    display: none;
  }

  /deep/ .el-scrollbar__wrap {
    overflow: auto;
    overflow-x: hidden;
  }

  /deep/ .el-scrollbar__view {
    height: 100%;
  }
}
</style>
